import React from 'react'

export const rdmSentences = {
    "fr": [
        "décoration du nouvel an chinois, parade du nouvel an chinois, char du dragon, moines, dieux asiatiques géants, foule de gens, kowloon, éclairage dramatique, peinture mate, chef d'œuvre.",
        "une salle commune circulaire remplie d'horloges anciennes, très détaillé, concept art, illustration, steampunk, fantasy, mécanique, 4 k, trending on artstation",
        "Un gratte-ciel d'appartement de style japonais, Hautement détaillé, Composition épique, Gros plan, Grand angle, par Miyazaki, Nausicaa Ghibli, Breath of The Wild",
        "collines au coucher du soleil avec un champ de coquelicots, paysage détaillé, paysage classique peint, tendance dans artstation, dans le style du studio ghibli, wlop, 8k artstation,",
        "photo sous-marine grand angle en gros plan d'un chien sous l'eau, flash, obturateur rapide, yeux détaillés, fourrure complexe, par Annie liebowitz",
        "photo de Marc Adamus d'une tour citadelle en vitrail dans un paysage magnifique, avec un labyrinthe de haies et des vues sur la mer, éclairage cinématographique",
        "Hibou bleu anthropomorphe, grands yeux verts, beaucoup de détails, portrait, armure finement détaillée, éclairage cinématographique, conception métallique filigrane complexe, 8k, unreal engine, rendu octane, réaliste, rendu redshift",
        "un beau cristal rouge, rougeoyant, rétroéclairé, un rendu d'octane,",
        "Portrait du rétro David Bowie, fuites de lumière extrêmes, flou de mouvement, Tom Bagshaw",
        "un scarabée égyptien aux ailes ouvertes, fait d'opale de roche sculptée et d'ailes de morphos bleus géants, symétriques",
        "un rayon de miel avec des abeilles, aquarelle d'Ilya Kuvshinov, très détaillée, ultra détaillée, proprtion parfaite de l'abeille,",
        "Milkyway dans une bouteille en verre, 4K, unreal engine, octane render,", "nouvel an, feux d'artifice, peinture, tour eiffel",
    ],
    "en": [
        "Chinese New Year decoration, Chinese New Year parade, dragon chariot, monks, giant Asian gods, crowd of people, kowloon, dramatic lighting, matte painting, masterpiece.",
        "a circular common room filled with antique clocks, very detailed, concept art, illustration, steampunk, fantasy, mechanical, 4k, trending on artstation",
        "A Japanese style flat skyscraper, Highly detailed, Epic composition, Close-up, Wide angle, by Miyazaki, Nausicaa Ghibli, Breath of The Wild",
        "hills at sunset with a field of poppies, detailed landscape, classic painted landscape, trend in artstation, in the style of studio ghibli, wlop, 8k artstation,",
        "underwater wide angle close up photo of a dog underwater, flash, fast shutter, detailed eyes, complex fur, by Annie liebowitz",
        "Marc Adamus' photo of a stained glass citadel tower in a beautiful landscape, with a labyrinth of hedges and views of the sea, cinematic lighting.",
        "anthropomorphic blue owl, large green eyes, lots of detail, portrait, finely detailed armour, cinematic lighting, complex filigree metal design, 8k, unreal engine, octane render, realistic, redshift render,",
        "a beautiful red crystal, glowing, backlit, octane rendering,",
        "Portrait of retro David Bowie, extreme light leakage, motion blur, Tom Bagshaw",
        "an Egyptian scarab with open wings, made of carved rock opal and giant blue morpho wings, symmetrical",
        "a honeycomb with bees, watercolour by Ilya Kuvshinov, very detailed, ultra detailed, perfect proprtion of the bee,",
        "Milkyway in a glass bottle, 4K, unreal engine, octane render,", "new year, fireworks, painting, eiffel tower"
    ],
    "it": [
        "Decorazione del Capodanno cinese, parata del Capodanno cinese, carro del drago, monaci, divinità asiatiche giganti, folla di persone, Kowloon, illuminazione drammatica, pittura opaca, capolavoro.",
        "una sala comune circolare piena di orologi antichi, molto dettagliata, concept art, illustrazione, steampunk, fantasy, meccanica, 4k, di tendenza su artstation",
        "Un grattacielo piatto in stile giapponese, Molto dettagliato, Composizione epica, Primo piano, Grandangolo, di Miyazaki, Nausicaa Ghibli, Breath of The Wild",
        "colline al tramonto con un campo di papaveri, paesaggio dettagliato, paesaggio dipinto classico, tendenza in artstation, nello stile di studio ghibli, wlop, 8k artstation,",
        "foto grandangolare subacquea di un cane sott'acqua, flash, otturatore veloce, occhi dettagliati, pelliccia complessa, di Annie Liebowitz",
        "La foto di Marc Adamus di una torre della cittadella in vetro colorato in un bellissimo paesaggio, con un labirinto di siepi e vista sul mare, illuminazione cinematografica.",
        "gufo blu antropomorfo, grandi occhi verdi, molti dettagli, ritratto, armatura finemente dettagliata, illuminazione cinematografica, complesso design in metallo filigranato, 8k, unreal engine, rendering octane, realistico, rendering redshift,",
        "un bellissimo cristallo rosso, incandescente, retroilluminato, resa ottanica,",
        "Ritratto di David Bowie retrò, dispersione di luce estrema, sfocatura di movimento, Tom Bagshaw",
        "uno scarabeo egiziano con ali aperte, fatto di opale di roccia intagliato e ali giganti di morpho blu, simmetriche",
        "nido d'api con api, acquerello di Ilya Kuvshinov, molto dettagliato, ultra dettagliato, perfetta rappresentazione dell'ape",
        "Milkyway in una bottiglia di vetro, 4K, unreal engine, octane render,", "Capodanno, fuochi d'artificio, pittura, torre eiffel"]
}

