import React, { Dispatch, SetStateAction } from "react";
import { LanguageTheme } from "./language.model";

interface LanguageContextProps {
  languageTheme: LanguageTheme;
  setLanguageTheme: Dispatch<SetStateAction<LanguageTheme>>;
}

export const LanguageContext = React.createContext<LanguageContextProps>({
  languageTheme: {
    url: "",
    sentence: "",
  },
} as LanguageContextProps);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentLanguageTheme, setCurrentLanguageTheme] =
    React.useState<LanguageTheme>({
      url: "",
      sentence: "",
    });

  return (
    <LanguageContext.Provider
      value={{
        languageTheme: currentLanguageTheme,
        setLanguageTheme: setCurrentLanguageTheme,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
export const useLanguage = () => React.useContext(LanguageContext);
