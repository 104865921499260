import React from "react";
import "./style.css";
import i18n from "i18next";

function FlagsIndex() {
  // console.log(languageTheme);
  async function handleClick(lang: string) {
    await i18n.changeLanguage(lang);
  }

  return (
    <div className="flags">
      <div onClick={() => handleClick("fr")} className="fr" />
      <div onClick={() => handleClick("en")} className="en" />
      <div onClick={() => handleClick("it")} className="it" />
    </div>
  );
}

export default FlagsIndex;
