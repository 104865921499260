import React from "react";
import "./style.css";

function LoaderIndex() {
  return (
    <div className="wrap">
      <div className="spinner"></div>
    </div>
  );
}

export default LoaderIndex;
