import React from "react";
import "./style.css";
import imgDemo_fr from "../../images/am_fr.png";
import imgDemo_en from "../../images/am_en.png";
import imgDemo_it from "../../images/am_it.png";
import imgCopy from "../../images/copy.png";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/language/language.context";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import i18n from "i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// npm i --save-dev @types/file-saver

function ScreenIndex() {
  const { t } = useTranslation();
  const { languageTheme } = useLanguage();

  // use sharp to add a watermark to an image
  const notify = () => toast.info(t("copy"));

  const downloadV2 = async () => {
    // if languageTheme.url is not empty download the image in google chrome

    if (languageTheme.url) {
      const blob = await (await fetch(languageTheme.url)).blob();
      const objectUrl = URL.createObjectURL(blob);
      const j = document.createElement("a");
      j.href = objectUrl;
      j.download = `${new Date().toISOString()}.jpg`;
      document.body.appendChild(j);
      j.click();
      document.body.removeChild(j);

    }
  };


  return (

    <div className="screen">
      <ToastContainer theme="colored" />
      <div className="img">
        <img
          className="img-output"
          // if languageTheme.url is empty, use imgDemo + languageTheme.lang
          src={languageTheme.url ? languageTheme.url : i18n.language === "fr" ? imgDemo_fr : i18n.language === "it" ? imgDemo_it : imgDemo_en}
          alt="output to download"
        ></img>
      </div>
      <div className="btns">
        <div onClick={downloadV2} className="btn-download">
          <h6>{t("download")}</h6>
        </div>
        <div
          className="btn-shared">
          <div className="btn_wrap">
            <span>{t("share")}</span>
            <div className="container">

              {/* if (languageTheme.url) display the share buttons */}
              {languageTheme.url ? (
                <>
                  <div onClick={() => {
                    navigator.clipboard.writeText(
                      languageTheme.url ? languageTheme.url : i18n.language === "fr" ? imgDemo_fr : i18n.language === "it" ? imgDemo_it : imgDemo_en
                    );
                    notify();
                  }} className="copy-pasta">
                    <img src={imgCopy} alt="copy-pasta"></img>
                  </div>
                  <LinkedinShareButton url={languageTheme.url ? languageTheme.url : i18n.language === "fr" ? imgDemo_fr : i18n.language === "it" ? imgDemo_it : imgDemo_en} title="Ask Mona & Selas" summary=" vous souhaite un joyeux Noël" source={languageTheme.url ? languageTheme.url : i18n.language === "fr" ? imgDemo_fr : i18n.language === "it" ? imgDemo_it : imgDemo_en}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                  <FacebookShareButton
                    url={languageTheme.url ? languageTheme.url : i18n.language === "fr" ? imgDemo_fr : i18n.language === "it" ? imgDemo_it : imgDemo_en} quote="Ask Mona & Selas vous souhaite un joyeux Noël">
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </>
              ) : <div className="before-img" style={{ fontSize: 'clamp(0.5rem, 2vw, 0.8rem)' }}>{t("before-share")}</div>}




            </div>
          </div>
          {/* <h6>{t("share")}</h6> */}
        </div>
      </div>
    </div>
  );
}

export default ScreenIndex;
