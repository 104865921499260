import React from "react";
import "./style.css";
import logo from "../../images/logos.png";

function HeaderIndex() {
  const redirect = () => {
    window.location.href = "https://www.askmona.fr/";
  };
  return (
    <div onClick={redirect} className="logos">
      <img src={logo} loading="lazy" alt="logo"></img>
    </div>
  );
}

export default HeaderIndex;
