import React, { useEffect } from "react";
import "./App.css";
import "./i18n";
import HeaderIndex from "./components/header/HeaderIndex";
import FlagsIndex from "./components/flags/FlagsIndex";
import InputsIndex from "./components/inputs/InputsIndex";
import ScreenIndex from "./components/screen/ScreenIndex";
import FooterIndex from "./components/footer/FooterIndex";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Analytics } from '@vercel/analytics/react';


function App() {

  // const notify = () => toast.info("Joyeux Noel <3");
  // notify();


  return (
    <>
      <Analytics />
      <div className="App">
        <div className="dots"></div>
        <ToastContainer theme="colored" />
        <HeaderIndex />
        <FlagsIndex />
        <InputsIndex />
        <ScreenIndex />
        <FooterIndex />
      </div>
    </>
  );
}

export default App;
