import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/language/language.context";
import send from "../../images/send.png";
import LoaderIndex from "../loader/LoaderIndex";
import { createSelasClient } from "selas";
import i18n from "i18next";
import { rdmSentences } from '../inputs/sentences'

function InputsIndex() {
  const selas = createSelasClient();
  const translate = require("deepl");

  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState("");
  const { languageTheme, setLanguageTheme } = useLanguage();
  const [loading, setLoading] = React.useState(false);

  const get_random_sentence = (lang) => {
    const random_sentence = rdmSentences[lang][Math.floor(Math.random() * rdmSentences[lang].length)];
    setInputValue(random_sentence);
  }


  const fetch_result = (job_id) =>
    new Promise((resolve, reject) => {
      const interval = setInterval(async () => {

        const {
          data: results,
          error,
          message,
        } = await selas.getResults(job_id);
        if (results.length > 0) {
          clearInterval(interval);
          resolve(results);
          clearTimeout(timer);
        }
      }, 200);
      // if timout, reject the promise
      const timer = setTimeout(() => {
        clearInterval(interval);
        reject("Prompt API timeout");
        setLoading(false);
        // alert('Prompt API timeout, Retry please');

      }, 15000);

    });
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchApi(inputValue);
    }
  }
  async function fetchApi(stc: string) {
    if (loading === false && stc !== "") {
      setLoading(true);
      await translate({
        free_api: true,
        text: stc,
        source_lang: i18n.language,
        target_lang: "EN",
        auth_key: "699260bc-1f71-1002-f9b3-9476dafbdb1b:fx",
        // All optional parameters available in the official documentation can be defined here as well.
      })
        .then((result) => {
          stc = result.data.translations[0].text;
        })
        .catch((error) => {
          console.error(error);
        });
      // wait 2 seconds before fetching the api
      await selas.signIn("benjamin@selas.studio", "tromtrom");
      const {
        data: job,
        error,
        message,
      } = await selas.runStableDiffusion(
        stc + ", artstation high quality, lots of detail, finely detailed, cinematic lighting, complex filigree metal design, 8k, octane render, realistic, redshift render",
        512,
        512,
        28,
        10,
        "k_euler",
        1,
        "jpg",
        false,
        "",
        { is_dirty: false, branch: "main", name: ".*" }
      );
      console.log(job);
      await fetch_result(job.id).then((res) =>

        setLanguageTheme({
          ...languageTheme,
          sentence: stc,
          url: res[0].uri,
        })
      );

      setLoading(false);
    }
  }

  return (
    <div className="inputs">
      <div className="titre">{t("title")}</div>
      <div className="buttonIn">
        <input
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
          className="input-sentence"
          type="text"
          value={inputValue}
          placeholder={t("placeholder")}
        />

        <div onClick={() => fetchApi(inputValue)} className="button-div">
          {loading ? (
            <LoaderIndex />
          ) : (
            <img className="img-send" src={send} alt="bouton envoyer"></img>
          )}
        </div>
      </div>
      <div onClick={() => get_random_sentence(i18n.language)} style={{ color: "white", fontSize: '0.7rem', textAlign: 'end', width: '80%', textDecoration: 'underline', cursor: 'pointer' }}>{t("idea")}</div>
    </div>
  );
}

export default InputsIndex;
